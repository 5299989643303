import React from "react"

const Headline = ({
  firstHeadline,
  title,
  titleTwo,
  titleThree,
  highlightedSpan,
  subtitle,
  subtitleTwo,
}) => {
  return firstHeadline ? (
    <h1
      className="font-headlineRegular text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl max-w-full sm:max-w-[600px] md:max-w-[700px] lg:max-w-4xl text-offWhite text-shadow"
      data-sal="slide-down"
      data-sal-delay="150"
      data-sal-easing="ease"
      data-sal-duration="2000"
    >
      <span className="font-headlineBold text-accentBlue">{title}</span>{" "}
      {highlightedSpan ? (
        <span className="inline-block sm:inline">{highlightedSpan}</span>
      ) : null}{" "}
      {subtitle}{" "}
      <span className="font-headlineBold text-accentBlue">{titleTwo}</span>{" "}
      {subtitleTwo}{" "}
      <span className="font-headlineBold text-accentBlue">{titleThree}</span>
    </h1>
  ) : (
    <h3
      className="font-headlineRegular text-3xl sm:text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl max-w-full sm:max-w-[600px] md:max-w-[700px] lg:max-w-3xl text-offWhite text-shadow"
      data-sal="slide-down"
      data-sal-delay="150"
      data-sal-easing="ease"
      data-sal-duration="2000"
    >
      <span className="font-headlineBold text-accentBlue">{title}</span>{" "}
      {highlightedSpan ? (
        <span className="inline-block sm:inline">{highlightedSpan}</span>
      ) : null}{" "}
      {subtitle}{" "}
      <span className="font-headlineBold text-accentBlue">{titleTwo}</span>{" "}
      {subtitleTwo}{" "}
      <span className="font-headlineBold text-accentBlue">{titleThree}</span>
    </h3>
  )
}

export default Headline
