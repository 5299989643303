import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GridWrapper from "../components/GridWrapper"
import Headline from "../components/Headline"
import SubText from "../components/SubText"

const ThankYouPage = () => (
  <Layout>
    <Seo title="Thank You For Your Submission" />
    <GridWrapper>
      <div className="md:block hidden"></div>
      <section
        id="thank-you"
        className="flex flex-col justify-start py-14 sm:py-20 md:py-36 2xl:py-48 text-offWhite z-10"
      >
        <Headline title="Thank you!" />
        <SubText subText="Thank you for you for reaching out! I will be in touch shortly within the next 24 hours. If you don't hear from me, there's a chance Netlify's form service wrongly tagged your message as spam, so please don't hesitate to contact me via email or phone listed on my resume." />
        <div className="md:block hidden"></div>
      </section>
    </GridWrapper>
  </Layout>
)

export default ThankYouPage
