import React from "react"

const SubText = ({ noMarginTop, subText, externalLink, linkName }) => {
  return (
    <div
      data-sal="slide-down"
      data-sal-delay="150"
      data-sal-easing="ease"
      data-sal-duration="2000"
      className={`text-sm md:text-base leading-6 max-w-full sm:max-w-md md:max-w-lg lg:max-w-xl text-offWhite ${
        noMarginTop ? "mt-0" : "mt-10 md:mt-12"
      }`}
    >
      {externalLink ? (
        <p>
          {subText}
          <a
            className="text-accentYellow underline underline-offset-2 decoration-solid hover:text-accentBlue transition duration-150"
            href={externalLink}
          >
            {linkName}
          </a>
        </p>
      ) : (
        <p>{subText}</p>
      )}
    </div>
  )
}

export default SubText
